import React, {useEffect, useState} from 'react';
import {Container, Header, Image, Grid, Segment, Button, Icon, List, Table, Card, Menu} from "semantic-ui-react";

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component'
import 'react-vertical-timeline-component/style.min.css';
import './LotTrace.css'
import blindmanBreweryLogo from '../../assets/blindmanLogo.png'
import { useHistory } from 'react-router-dom';
import hopsImage from '../../assets/hops.jpg';
import maltImage from '../../assets/malting.jpg'

import WheatSVG from '../../assets/barley_green.svg'
import maltSVG from '../../assets/malt_green.svg'
import hopsSVG from '../../assets/hops_green.svg'
import brewSVG from '../../assets/brewery_green.svg'

import farmerImage from '../../assets/placeholder.jpg'
import ariel from '../../assets/brewer/Ariel.png';
import brewing from '../../assets/brewer/Brewing.png';
import mackenzie from '../../assets/brewer/MacKenzie.png';
import sarah from '../../assets/brewer/Sarah.png';
import Slider from 'react-slick';
import beerGlass from '../../assets/beerGlass.png'

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


const LotTrace = props => {

    let history = useHistory();

    const [mount, setMount] = useState(false);
    let ga = props.ga;

    if(!mount){
        setMount(true);
    }

    useEffect(() => {
        ga.pageview(window.location.pathname)
    }, [mount]);

    const traceId = window.location.pathname.split('/')[2]
    let [traceData, setTraceData] = useState(null);
    let [farmerIndex, setFarmerIndex] = useState(0);
    let [farmerCount, setFarmerCount] = useState(null)
    let [maltIndex, setMaltIndex] = useState(0);
    let [maltCount, setMaltCount] = useState(null);
    let [hopsIndex, setHopsIndex] = useState(0);
    let [hopsCount, setHopsCount] = useState(null);


    import(`../../batchInfo/${traceId}.js`).then(
        (data) => {
            setTraceData((data || {}).default);
        }
    ).catch( () => {
        setTraceData(null)
    })

    let previousFarmerDisabled = true;
    let futureFarmerDisabled = false;
    useEffect(() => {
        setFarmerCount(((traceData || {}).farmer ||[]).length)
        setMaltCount(((traceData || {}).malter ||[]).length)
        setHopsCount(((traceData || {}).hops ||[]).length)
    }, [traceData])
    useEffect(() => {
        previousFarmerDisabled = farmerIndex === 0;
        futureFarmerDisabled = (farmerIndex === farmerCount - 1);
    }, [farmerIndex])

    let previousMaltDisabled = true;
    let futureMaltDisabled = false;
    useEffect(() => {
        previousMaltDisabled = maltIndex === 0;
        futureMaltDisabled = (maltIndex === maltCount -1);
    })

    let previousHopsDisabled = true;
    let futureHopsDisabled = false;

    useEffect(() => {
        previousHopsDisabled = hopsIndex === 0;
        futureHopsDisabled = (hopsIndex === hopsCount - 1)
    })

    if (traceData) {

        return (
            <div className={'root-lot-trace'}>
                <Container className={'the-journey-header'}>
                    <Grid columns={3} className={'header-grid'} centered>
                        <Grid.Column verticalAlign={'top'}>
                            <Grid.Row textAlign={'left'}>
                                <Image src={blindmanBreweryLogo} className={'lot-trace-blindman-logo'} floated={'left'} size={'small'}/>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column verticalAlign={'middle'}>
                            <Grid.Row centered textAlign={'center'}>
                                <Header inverted>
                                    <div className={'header-text'}>
                                        Journey
                                    </div>
                                </Header>
                            </Grid.Row>
                        </Grid.Column>
                        <Grid.Column verticalAlign={'top'}>
                            <Grid.Row centered textAlign={'right'}>
                                <Menu secondary inverted floated={'right'}>
                                    <Menu.Item
                                        name={'Home'}
                                        onClick={event => {
                                            history.push('/')
                                        }}
                                    />
                                    <Menu.Item
                                        name={'Map'}
                                        onClick={event => {
                                            history.push(`/map/${traceId}`)
                                        }}
                                    />
                                    <Menu.Item
                                        name={'About Us'}
                                        onClick={event => {
                                            history.push(`/about/${traceId}`)
                                        }}
                                    />
                                </Menu>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                </Container>
                <Container className={'page-contents-lot-trace'}>
                    <Grid columns={1}>
                        <Grid.Column>
                            <VerticalTimeline
                                        layout={'1-column-left'}
                                    >
                                        <VerticalTimelineElement
                                            icon={
                                                    <Image src={WheatSVG}/>
                                            }
                                            iconStyle={{ background: '#fff', borderColor: '#4d8921'}}
                                            className="vertical-timeline-element--work"
                                        >
                                            <Grid columns={2} stackable className={'journey-step-grid'}>
                                                <Grid.Column>
                                                    {}
                                                    {/*<Image src={`./batchImages/${traceData.farmer[farmerIndex].image}`}/>*/}
                                                    <Slider
                                                        {...sliderSettings}
                                                        afterChange={(index) => {
                                                            setFarmerIndex(index);
                                                            ga.event({
                                                                category: 'User',
                                                                action: 'Browse Farmer'
                                                            })
                                                        }}
                                                    >
                                                        {traceData.farmer.map(element => {
                                                            return <Image rounded src={element.image}/>
                                                        })}
                                                    </Slider>
                                                    {/*<Image rounded src={traceData.farmer[farmerIndex].image}/>*/}
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Grid columns={1}>
                                                        <Grid.Row>
                                                            <Header as={'h2'} floated={'left'}>
                                                                Barley
                                                            </Header>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid columns={2}>
                                                                <Grid.Column className={'journey-step-headers'}>
                                                                    <List>
                                                                        <List.Item content>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Farmer
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item content>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Location
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item content>
                                                                            <Header floated={'left'} className={'grid-datapoint'} as={'h5'}>
                                                                                Field History
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item content>
                                                                            <Header floated={'left'}  className={'grid-datapoint'} as={'h5'}>
                                                                                Planted
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item content>
                                                                            <Header floated={'left'}  className={'grid-datapoint'} as={'h5'}>
                                                                                Harvested
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item content>
                                                                            <Header floated={'left'}  className={'grid-datapoint'} as={'h5'}>
                                                                                Variety
                                                                            </Header>
                                                                        </List.Item>
                                                                    </List>
                                                                </Grid.Column>
                                                                <Grid.Column className={'journey-step-data'}>
                                                                    <List>
                                                                        <List.Item content>
                                                                            <Header floated={'left'} className={'grid-datapoint'} as={'h4'}>
                                                                                {traceData.farmer[farmerIndex].name}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item content>
                                                                            <Header floated={'left'} className={'grid-datapoint'} as={'h5'}>
                                                                                {traceData.farmer[farmerIndex].location}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item content>
                                                                            <Header floated={'left'} className={'grid-datapoint'} as={'h5'}>
                                                                                {traceData.farmer[farmerIndex].fieldHistory}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item content>
                                                                            <Header floated={'left'} className={'grid-datapoint'} as={'h5'}>
                                                                                {traceData.farmer[farmerIndex].planted}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item content>
                                                                            <Header floated={'left'} className={'grid-datapoint'} as={'h5'}>
                                                                                {traceData.farmer[farmerIndex].harvested}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item content>
                                                                            <Header floated={'left'} className={'grid-datapoint'} as={'h5'}>
                                                                                {traceData.farmer[farmerIndex].variety}
                                                                            </Header>
                                                                        </List.Item>
                                                                    </List>
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column>
                                                                <Button className={'ui custom button'} onClick={(event => {
                                                                    history.push(`/moreinfo/barley/${traceId}`)
                                                                    ga.event({
                                                                        category: 'User',
                                                                        action: 'Barley More Info'
                                                                    })
                                                                })}>
                                                                    Discover More
                                                                </Button>
                                                                {/*{farmerIndex !== 0 && <Button icon={'arrow left'}  onClick={(event) => {*/}
                                                                {/*    setFarmerIndex(farmerIndex-1)*/}
                                                                {/*}}/>}*/}
                                                                {/*<Button  basic className={'farmer-count'}>*/}
                                                                {/*    {`${farmerIndex+1} / ${farmerCount}`}*/}
                                                                {/*</Button>*/}
                                                                {/*{farmerCount -1 !== farmerIndex && <Button icon={'arrow right'}  onClick={(event) => {*/}
                                                                {/*    setFarmerIndex(farmerIndex+1)*/}
                                                                {/*}}/>}*/}

                                                            </Grid.Column>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Grid.Column>
                                            </Grid>
                                            <Grid columns={1}>
                                                <Grid.Column textAlign={'center'}>
                                                    <Grid.Row textAlign={'center'}>

                                                    </Grid.Row>
                                                </Grid.Column>
                                            </Grid>

                                        </VerticalTimelineElement>
                                        <VerticalTimelineElement
                                            icon={
                                                <Image src={maltSVG}/>
                                            }
                                            iconStyle={{ background: '#fff', borderColor: '#4d8921'}}
                                        >
                                            <Grid columns={2} stackable>
                                                <Grid.Column>
                                                    <Grid columns={1}>
                                                        <Grid.Row>
                                                            <Header as={'h2'} floated={'left'}>
                                                                Malting
                                                            </Header>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid.Column   only={'mobile'}>
                                                                {/*<Image src={`../../assets/batchImages/${((traceData || {}).farmer || [])[0].image}`}/>*/}
                                                                <Slider
                                                                    {...sliderSettings}
                                                                    afterChange={(index) => {
                                                                        setMaltIndex(index);
                                                                        ga.event({
                                                                            category: 'User',
                                                                            action: 'Browse Malt'
                                                                        })
                                                                    }}
                                                                    className={'mobile-image-slider'}
                                                                >
                                                                    {traceData.malter.map(element => {
                                                                        return <Image rounded src={element.image}/>
                                                                    })}
                                                                </Slider>
                                                                {/*<Image rounded src={traceData.malter[maltIndex].image}/>*/}
                                                            </Grid.Column>
                                                            <Grid columns={2}>
                                                                <Grid.Column className={'journey-step-headers'}>
                                                                    <List>
                                                                        <List.Item >
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Supplier
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Location
                                                                            </Header>
                                                                        </List.Item>
                                                                        {/*<List.Item>*/}
                                                                        {/*    <Header floated={'left'} as={'h5'}>*/}
                                                                        {/*        Name of Maltster*/}
                                                                        {/*    </Header>*/}
                                                                        {/*</List.Item>*/}
                                                                        {/*<List.Item>*/}
                                                                        {/*    <Header floated={'left'} as={'h5'}>*/}
                                                                        {/*        Barley Variety*/}
                                                                        {/*    </Header>*/}
                                                                        {/*</List.Item>*/}
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Malt Style
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Batch
                                                                            </Header>
                                                                        </List.Item>
                                                                    </List>
                                                                </Grid.Column>
                                                                <Grid.Column className={'journey-step-data'}>
                                                                    <List>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.malter[maltIndex].business}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.malter[maltIndex].location}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.malter[maltIndex].maltStyle}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.malter[maltIndex].batch}
                                                                            </Header>
                                                                        </List.Item>
                                                                    </List>
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Button className={'ui custom button'} onClick={(event => {
                                                                history.push(`/moreinfo/malt/${traceId}`)
                                                                ga.event({
                                                                    category: 'User',
                                                                    action: 'More Info Malt'
                                                                })
                                                            })}>
                                                                Discover More
                                                            </Button>
                                                            {/*{maltIndex !== 0 && <Button icon={'arrow left'}  onClick={(event) => {*/}
                                                            {/*    setMaltIndex(maltIndex-1)*/}
                                                            {/*}}/>}*/}
                                                            {/*<Button  basic className={'farmer-count'}>*/}
                                                            {/*    {`${maltIndex+1} / ${maltCount}`}*/}
                                                            {/*</Button>*/}
                                                            {/*{maltCount -1 !== maltIndex && <Button icon={'arrow right'}  onClick={(event) => {*/}
                                                            {/*    setMaltIndex(maltIndex+1)*/}
                                                            {/*}}/>}*/}
                                                        </Grid.Row>
                                                    </Grid>
                                                </Grid.Column>
                                                <Grid.Column only={'computer'}>
                                                    {/*<Image src={`../../assets/batchImages/${((traceData || {}).farmer || [])[0].image}`}/>*/}
                                                    <Slider
                                                        {...sliderSettings}
                                                        afterChange={(index) => {
                                                            setMaltIndex(index);
                                                            ga.event({
                                                                category: 'User',
                                                                action: 'Browse Malt'
                                                            })
                                                        }}
                                                    >
                                                        {traceData.malter.map(element => {
                                                            return <Image rounded src={element.image}/>
                                                        })}
                                                    </Slider>
                                                    {/*<Image rounded src={traceData.malter[maltIndex].image}/>*/}
                                                </Grid.Column>
                                            </Grid>
                                        </VerticalTimelineElement>
                                        <VerticalTimelineElement
                                            icon={
                                                <Image  src={hopsSVG}/>
                                            }
                                            iconStyle={{ background: '#fff', borderColor: '#4d8921'}}
                                            className="vertical-timeline-element"
                                        >
                                            <Grid columns={2} stackable>
                                                <Grid.Column>
                                                    {/*<Image src={`../../assets/batchImages/${((traceData || {}).farmer || [])[0].image}`}/>*/}
                                                    <Slider
                                                        {...sliderSettings}
                                                        afterChange={(index) => {
                                                            setHopsIndex(index);
                                                            ga.event({
                                                                category: 'User',
                                                                action: 'Browse Hops'
                                                            })
                                                        }}
                                                    >
                                                        {traceData.hops.map(element => {
                                                            return <Image rounded src={hopsImage}/>
                                                        })}
                                                    </Slider>
                                                    {/*<Image rounded src={hopsImage}/>*/}
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Grid columns={1}>
                                                        <Grid.Row>
                                                            <Header as={'h2'} floated={'left'}>
                                                                Hops
                                                            </Header>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid columns={2}>
                                                                <Grid.Column className={'journey-step-headers'}>
                                                                    <List>
                                                                        <List.Item >
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Supplier
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Location
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Variety
                                                                            </Header>
                                                                        </List.Item>
                                                                        {/*<List.Item>*/}
                                                                        {/*    <Header floated={'left'} as={'h5'}>*/}
                                                                        {/*        Volume Used*/}
                                                                        {/*    </Header>*/}
                                                                        {/*</List.Item>*/}
                                                                    </List>
                                                                </Grid.Column>
                                                                <Grid.Column className={'journey-step-data'}>
                                                                    <List>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h4'} className={'grid-datapoint'}>
                                                                                {traceData.hops[hopsIndex].business}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.hops[hopsIndex].location}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.hops[hopsIndex].variety}
                                                                            </Header>
                                                                        </List.Item>
                                                                        {/*<List.Item>*/}
                                                                        {/*    <Header floated={'left'} as={'h5'}>*/}
                                                                        {/*        {traceData.hops.volumeUsed}*/}
                                                                        {/*    </Header>*/}
                                                                        {/*</List.Item>*/}
                                                                    </List>
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Button className={'ui custom button'} onClick={(event => {
                                                                history.push(`/moreinfo/hops/${traceId}`)
                                                                ga.event({
                                                                    category: 'User',
                                                                    action: 'More Info Hops'
                                                                })
                                                            })}>
                                                                Discover More
                                                            </Button>
                                                            {/*{hopsIndex !== 0 && <Button icon={'arrow left'}  onClick={(event) => {*/}
                                                            {/*    setHopsIndex(hopsIndex-1)*/}
                                                            {/*}}/>}*/}
                                                            {/*<Button  basic size={'mini'} className={'farmer-count'}>*/}
                                                            {/*    {`${hopsIndex+1} / ${hopsCount}`}*/}
                                                            {/*</Button>*/}
                                                            {/*{hopsCount -1 !== hopsIndex && <Button icon={'arrow right'}  onClick={(event) => {*/}
                                                            {/*    setHopsIndex(hopsIndex+1)*/}
                                                            {/*}}/>}*/}
                                                        </Grid.Row>
                                                    </Grid>
                                                </Grid.Column>
                                            </Grid>

                                        </VerticalTimelineElement>
                                        <VerticalTimelineElement
                                            icon={
                                                <Image src={brewSVG}/>
                                            }
                                            iconStyle={{ background: '#fff', borderColor: '#4d8921'}}
                                        >
                                            <Grid columns={2} stackable>
                                                <Grid.Column only={'mobile'} className={'mobile-image-slider'}>
                                                    {/*<Image src={`../../assets/batchImages/${((traceData || {}).farmer || [])[0].image}`}/>*/}
                                                    {/*<Image rounded src={farmerImage}/>*/}
                                                    <Slider {...sliderSettings}>
                                                        <Image src={brewing}/>
                                                        <Image src={ariel}/>
                                                        <Image src={mackenzie}/>
                                                        <Image src={sarah}/>
                                                    </Slider>
                                                </Grid.Column>
                                                <Grid.Column>
                                                    <Grid columns={1}>
                                                        <Grid.Row>
                                                            <Header as={'h2'} floated={'left'}>
                                                                Brewery
                                                            </Header>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Grid columns={2}>
                                                                <Grid.Column className={'journey-step-headers'}>
                                                                    <List>
                                                                        <List.Item >
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Brewery
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Location
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Brewer
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Batch Code
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Beer Style
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Malt Used (kg)
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Hops Used (kg)
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Brew Date
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Canning Date
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Lead Packager
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                Total Cans
                                                                            </Header>
                                                                        </List.Item>
                                                                    </List>
                                                                </Grid.Column>
                                                                <Grid.Column className={'journey-step-data'}>
                                                                    <List>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.brewer.business}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.brewer.location}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.brewer.brewer}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.brewer.batchCode}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.brewer.beerStyle}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.brewer.malt}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.brewer.hops}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.brewer.dateBrewed}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.brewer.canningDate}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.brewer.packager}
                                                                            </Header>
                                                                        </List.Item>
                                                                        <List.Item>
                                                                            <Header floated={'left'} as={'h5'} className={'grid-datapoint'}>
                                                                                {traceData.brewer.totalCans}
                                                                            </Header>
                                                                        </List.Item>
                                                                    </List>
                                                                </Grid.Column>
                                                            </Grid>
                                                        </Grid.Row>
                                                        <Grid.Row>
                                                            <Button className={'ui custom button'} onClick={(event => {
                                                                history.push(`/moreinfo/brew/${traceId}`)
                                                                ga.event({
                                                                    category: 'User',
                                                                    action: 'More Info Brewer'
                                                                })
                                                            })}>
                                                                Discover More
                                                            </Button>
                                                        </Grid.Row>
                                                    </Grid>
                                                </Grid.Column>
                                                <Grid.Column only={'computer'}>
                                                    {/*<Image src={`../../assets/batchImages/${((traceData || {}).farmer || [])[0].image}`}/>*/}
                                                    {/*<Image rounded src={farmerImage}/>*/}
                                                    <Slider {...sliderSettings}>
                                                        <Image src={brewing}/>
                                                        <Image src={ariel}/>
                                                        <Image src={mackenzie}/>
                                                        <Image src={sarah}/>
                                                    </Slider>
                                                </Grid.Column>
                                            </Grid>
                                        </VerticalTimelineElement>
                                    </VerticalTimeline>
                        </Grid.Column>
                    </Grid>

                    <Grid columns={2}>

                        <Grid.Column>
                            <Menu secondary>
                                <Menu.Item
                                    name={'home'}
                                    onClick={event => {
                                        history.push('/')
                                    }}
                                />

                                <Menu.Item
                                    name={'About Us'}
                                    onClick={event => {
                                        history.push(`/about/${traceId}`)
                                    }}
                                />
                                <Menu.Item
                                    name={'Map'}
                                    onClick={event => {
                                        history.push(`/map/${traceId}`)
                                    }}
                                />
                            </Menu>
                        </Grid.Column>
                        <Grid.Column floated={'right'}>
                            <Container textAlign={'right'}>
                                <Menu.Item
                                    href={"https://www.facebook.com/blindmanbrewing/"}
                                >
                                    <Icon size={'large'} color={'black'} link name={'facebook'}/>
                                </Menu.Item>
                                <Menu.Item
                                    href={"https://twitter.com/blindmanbrewery"}
                                >
                                    <Icon size={'large'} color={'green'} link name={'twitter'}/>
                                </Menu.Item>
                                <Menu.Item
                                    href={"https://www.instagram.com/blindmanbrewery/"}
                                >
                                    <Icon size={'large'} color={'black'} link name={'instagram'}/>
                                </Menu.Item>
                            </Container>
                        </Grid.Column>
                    </Grid>
                </Container>
            </div>
        );
    } else {
        return (
            <div className={'root-lot-not-found'}>
                <Container textAlign={'center'} className={'not-found-container'}>
                    <Grid columns={1} className={'not-found-container'}>
                        <Grid.Column verticalAlign={'middle'}>
                            <Grid.Row>
                                <Segment textAlign={'center'} className={'not-found-segment'}>
                                    <Header>Lot number not found...</Header>
                                    <Button
                                        onClick={(event, data) => {
                                            history.push('/')
                                        }}
                                        color={'green'}
                                    >
                                        Return to home page
                                    </Button>
                                </Segment>
                            </Grid.Row>
                        </Grid.Column>
                    </Grid>
                </Container>
            </div>
        )
    }
};

const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
}

LotTrace.propTypes = {

};

export default LotTrace;
