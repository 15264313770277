import styled from 'styled-components'

const ModalBackground = styled.div`
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    background-color: rgba(0,0,0,0.5);
`

const ModalBody = styled.div`
    background-color: white;
    margin: 10% auto;
    width: 800px;
    height: 421px;
    border-radius: 20px;
    position: relative;
`

const ModalButton = styled.button`
    position: absolute;
    top: 10px;
    right: 10px;
    border-radius: 50%;
    width 40px;
    height: 40px;
    border: none;
    color: gray;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 700;
    z-index: 2;
`

const Modal = ({ showModal, setShowModal, children }) => {

    return (
        <>
            {showModal && (
                <ModalBackground onClick={() => setShowModal(false)}>
                    <ModalBody onClick={(e) => e.stopPropagation()}>
                        <ModalButton onClick={() => setShowModal(false)}>X</ModalButton>
                        {children}
                    </ModalBody>
                </ModalBackground>
            )}
        </>

    )
}

export default Modal