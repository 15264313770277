import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Card, Container, Grid, Header, Icon, Image, List, Menu, Segment} from "semantic-ui-react";
import blindmanBreweryLogo from "../../assets/blindmanLogo.png";
import {useHistory} from "react-router-dom";
import blindMan from '../../assets/about/blindman.png';
import rahrMalting from '../../assets/about/rahrMalting.png';
import redShed from '../../assets/about/redShed.png'
import gdLogo from '../../assets//about/GD.png'
import './About.css'


const About = props => {

    let history = useHistory();

    const traceId = window.location.pathname.split('/')[2]
    const [mount, setMount] = useState(false);
    let ga = props.ga;

    if(!mount){
        setMount(true);
    }

    useEffect(() => {
        ga.pageview(window.location.pathname)
    }, [mount]);

    return (
        <div className={'root-about'}>
            <Container className={'the-about-header'}>
                <Grid columns={3} className={'header-grid'} centered>
                    <Grid.Column verticalAlign={'top'}>
                        <Grid.Row textAlign={'left'}>
                            <Image src={blindmanBreweryLogo} className={'lot-trace-blindman-logo'} floated={'left'} size={'small'}/>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column verticalAlign={'middle'}>
                        <Grid.Row centered textAlign={'center'}>
                            <Header inverted>
                                <div className={'the-about-header-text'}>
                                    ABOUT US
                                </div>
                            </Header>
                        </Grid.Row>
                    </Grid.Column>
                    <Grid.Column verticalAlign={'top'}>
                        <Grid.Row centered textAlign={'right'}>
                            <Menu secondary inverted floated={'right'}>
                                <Menu.Item
                                    name={'Home'}
                                    onClick={event => {
                                        history.push('/')
                                    }}
                                />
                                <Menu.Item
                                    name={'Journey'}
                                    onClick={event => {
                                        history.push(`/trace/${traceId}`)
                                    }}
                                />
                                <Menu.Item
                                    name={'Map'}
                                    onClick={event => {
                                        history.push(`/map/${traceId}`)
                                    }}
                                />

                            </Menu>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
            </Container>
            <Container className={'page-contents-about'}>
                <Grid columns={1}>
                    <Grid.Column>
                        <Grid.Row>
                            <Container className={'about-container-row'}>
                                <Grid columns={2} stackable>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Image rounded src={blindMan}/>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Container textAlign={'left'}>
                                                <div className={'section-heading-about'}>
                                                    Blindman Brewing
                                                </div>
                                            </Container>
                                            <Container textAlign={'left'}>
                                                <p className={'section-text-about'}>
                                                    Like our namesake river, we meander—in our case, through beer styles and brewing techniques. From kettle-soured beer to heavily dry-hopped ales to barrel-aged and high gravity brews, we believe in creating innovative and quality craft beer, using the best ingredients. We believe in supporting the places we live in and the  people that live there. Our growing crew brews out of sunny  Lacombe, Alberta on a 30-barrel, tricked-out, 2-vessel brewhouse.  We blew the engine on our '79 van, The Dream Machine, on our first  delivery run so now we drive in shiny new vehicles with no souls.
                                                </p>
                                            </Container>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                            <Container className={'about-container-row'}>
                                <Grid columns={2} stackable>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Container textAlign={'left'}>
                                                <div className={'section-heading-about'}>
                                                    Rahr Malting
                                                </div>
                                            </Container>
                                            <Container textAlign={'left'}>
                                                <p className={'section-text-about'}>
                                                    Located in Alix, Alberta, was constructed in the middle of Alberta’s prime barley-growing region in 1993. This central location ensures both dependable supplies of premium quality malting barley and proximity to our key markets. This modern and efficient tower malthouse has an annual capacity of 140,000 MT.  Only the best Canadian malting barley, recognized around the world for its brewing quality, goes into Rahr’s products.
                                                </p>
                                            </Container>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Image rounded src={rahrMalting}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                            <Container className={'about-container-row'}>
                                <Grid columns={2} stackable>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Image rounded src={redShed}/>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Container textAlign={'left'}>
                                                <div className={'section-heading-about'}>
                                                    Red Shed Malting
                                                </div>
                                            </Container>
                                            <Container textAlign={'left'}>
                                                <p className={'section-text-about'}>
                                                    Combines a 4th generation family farm with Alberta's first craft malthouse, merging the Hamill parent's love of agriculture with the Hamill son's love of craft beer and whisky. While creating Canadian, Sustainable, and Traceable malts, they're also focused on supporting their community, having been voted Community Builder of the Year by the Alberta breweries at the 2019 and 2020 Alberta Beer Awards. Being connected to the farm ensures their quality extends from the best seed to the best malt, to the best beer in your glass.
                                                </p>
                                            </Container>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                            <Container className={'about-container-row'}>
                                <Grid columns={2} stackable>
                                    <Grid.Row>
                                        <Grid.Column>
                                            <Container textAlign={'left'}>
                                                <div className={'section-heading-about'}>
                                                    Grain Discovery
                                                </div>
                                            </Container>
                                            <Container textAlign={'left'}>
                                                <p className={'section-text-about'}>
                                                    Based out of Prince Edward County, Ontario. Grain Discovery is an agricultural technology company that has built a digital ecosystem focusing on price discovery and traceability. Founded by leaders in the commodities, trading and blockchain technology space, Grain Discovery develops innovative solutions to the logistical challenges of the commodities market, using blockchain technology to increase the transparency, efficiency and traceability of the supply chain.
                                                </p>
                                            </Container>
                                        </Grid.Column>
                                        <Grid.Column>
                                            <Image rounded src={gdLogo}/>
                                        </Grid.Column>
                                    </Grid.Row>
                                </Grid>
                            </Container>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>

                <Grid columns={2}>

                    <Grid.Column>
                        <Menu secondary>
                            <Menu.Item
                                name={'home'}
                                onClick={event => {
                                    history.push('/')
                                }}
                            />
                            <Menu.Item
                                name={'Journey'}
                                onClick={event => {
                                    history.push(`/trace/${traceId}`)
                                }}
                            />
                            <Menu.Item
                                name={'Map'}
                                onClick={event => {
                                    history.push(`/map/${traceId}`)
                                }}
                            />
                        </Menu>
                    </Grid.Column>
                    <Grid.Column floated={'right'}>
                        <Container textAlign={'right'}>
                            <Menu.Item
                                href={"https://www.facebook.com/blindmanbrewing/"}
                            >
                                <Icon size={'large'} color={'black'} link name={'facebook'}/>
                            </Menu.Item>
                            <Menu.Item
                                href={"https://twitter.com/blindmanbrewery"}
                            >
                                <Icon size={'large'} color={'green'} link name={'twitter'}/>
                            </Menu.Item>
                            <Menu.Item
                                href={"https://www.instagram.com/blindmanbrewery/"}
                            >
                                <Icon size={'large'} color={'black'} link name={'instagram'}/>
                            </Menu.Item>

                        </Container>

                    </Grid.Column>

                </Grid>

            </Container>
        </div>
    );
};

About.propTypes = {

};

export default About;
