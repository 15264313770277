import React, { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import BeerIcon from "../../../assets/timeline_icon/beer.png";
import BarleyIcon from "../../../assets/timeline_icon/barley.png";
import MaltIcon from "../../../assets/timeline_icon/malt.png";
import HopIcon from "../../../assets/timeline_icon/hop.png";

import Malt1 from "../../../assets/malt_illustration/malt_1.png";
import Malt2 from "../../../assets/malt_illustration/malt_2.png";
import Malt3 from "../../../assets/malt_illustration/malt_3.png";

import BarleyImage from "../../../assets/timeline_picture/barley_stage.png";
import MaltImage from "../../../assets/timeline_picture/malt_stage.png";
import HopImage from "../../../assets/timeline_picture/hops_stage.png";
import BeerImage from "../../../assets/timeline_picture/beer_stage.png";

const tabs = [
	{
		title: "Barley",
		body: "Maybe the farmer is your neighbour? Get to know them better and hear their stories and passion on why Canada grows the worlds best barley! Farmers have an opportunity to participate in consumer-driven, value-add programs that drives a price premium.",
		detail: {
			farmer: "Gellert Farms",
			location: "Assiniboia, SK",
			planted: "May, 2021",
			havested: "Sep, 2021",
			variety: "Copeland",
		},
		image: BarleyImage,
	},
	{
		title: "Hops",
		body: "You may recognise the taste of hops, but what is their role in the brewing process. How does the flavour profile impact the taste and longevity of the beer?",
		detail: {
			supplier: "Sasquatch",
			location: "Bushy Park, Tasmania",
			variety: "Simcoe",
		},
		image: HopImage,
	},
	{
		title: "Malting",
		body: "Did you know malting is a three-part process that has remained unchanged for centuries! Brewery Discovery gives you a glimpse into this age-old practice.",
		detail: {
			supplier: "Makers Malt",
			location: "Rosthern, SK",
			malt_style: "Prairie Chocolate",
			batch: "156-25",
		},
		image: MaltImage,
	},
	{
		title: "Brewing",
		body: "How fresh is your favourite bevvie? How much hops/barley are used? Who is the brew master and how did they make it taste like this! All these questions and more can now be finally answered!",
		detail: {
			brewery: "Saskatchewan River Pirate",
			location: "Patchewollock, SK",
			batch_code: "0786",
			beer_style: "Double IPA",
			malt_used: "1048 kg",
			hops_used: "51 kg",
			brewing_date: "19th Apr, 2022",
			canning_date: "8th May, 2022",
		},
		image: BeerImage,
	},
];

function Timeline() {
	const [currentTab, setCurrentTab] = useState(0);

	return (
		<div className="px-16 py-16 bg-background bg-background relative">
			<img
				className="hidden md:block absolute top-96 left-0"
				src={Malt1}
				alt="Malt Illustration"
			></img>
			<img
				className="hidden md:block absolute left-0 z-10 top-[1000px]"
				src={Malt2}
				alt="Malt Illustration"
			></img>
			<img
				className="hidden md:block absolute top-[600px] right-0"
				src={Malt3}
				alt="Malt Illustration"
			></img>
			<ul className="flex flex-wrap justify-center items-center space-x-1 tablet:space-x-0">
				<li>
					<button
						className={
							currentTab === 0
								? "inline-block py-3 px-3 text-white bg-orange-500 rounded-full transition-color duration-500"
								: "inline-block py-3 px-3 text-white bg-orange-200 rounded-full transition-color duration-500"
						}
						onClick={() => {
							setCurrentTab(0);
						}}
					>
						<img
							className="h-12 tablet:h-14 p-2"
							src={BarleyIcon}
							alt="Barley Icon"
						></img>
					</button>
				</li>
				<li>
					<div className="hidden tablet:block h-4 w-10 tablet:w-32 bg-orange-300 rounded-full tablet:mx-4 md:mx-10"></div>
				</li>
				<li>
					<button
						className={
							currentTab === 1
								? "inline-block py-3 px-3 text-white bg-orange-500 rounded-full transition-color duration-500"
								: "inline-block py-3 px-3 text-white bg-orange-200 rounded-full transition-color duration-500"
						}
						onClick={() => {
							setCurrentTab(1);
						}}
					>
						<img
							className="h-12 tablet:h-14 p-2"
							src={HopIcon}
							alt="Hop Icon"
						></img>
					</button>
				</li>
				<li>
					<div className="hidden tablet:block h-4 w-10 tablet:w-32 bg-orange-300 rounded-full tablet:mx-4 md:mx-10"></div>
				</li>
				<li>
					<button
						className={
							currentTab === 2
								? "inline-block py-3 px-3 text-white bg-orange-500 rounded-full transition-color duration-500"
								: "inline-block py-3 px-3 text-white bg-orange-200 rounded-full transition-color duration-500"
						}
						onClick={() => {
							setCurrentTab(2);
						}}
					>
						<img
							className="h-12 tablet:h-14 p-2"
							src={MaltIcon}
							alt="Malt Icon"
						></img>
					</button>
				</li>

				<li>
					<div className="hidden tablet:block h-4 w-10 tablet:w-32 bg-orange-300 rounded-full tablet:mx-4 md:mx-10"></div>
				</li>
				<li>
					<button
						className={
							currentTab === 3
								? "inline-block py-3 px-3 text-white bg-orange-500 rounded-full transition-color duration-500"
								: "inline-block py-3 px-3 text-white bg-orange-200 rounded-full transition-color duration-500"
						}
						onClick={() => {
							setCurrentTab(3);
						}}
					>
						<img
							className="h-12 tablet:h-14 p-2"
							src={BeerIcon}
							alt="Beer Icon"
						></img>
					</button>
				</li>
			</ul>
			<div className="flex flex-col tablet:flex-row justify-center space-y-10 tablet:space-y-0 tablet:space-x-20 items-start pt-20">
				<div className="flex flex-col">
					<h1 className="font-brewski text-5xl mt-0">
						{tabs[currentTab].title}
					</h1>
					<p className="text-xl max-w-2xl pt-4">{tabs[currentTab].body}</p>
				</div>
				<div className="hidden tablet:block h-64 w-[0.5px] bg-black"></div>
				<div className="text-2xl max-w-xl">
					{Object.entries(tabs[currentTab].detail).map(([key, value]) => (
						<p key={uuidv4()} className="text-2xl max-w-xl">
							<span className="font-bold font-brewski text-2xl">
								{key.replaceAll("_", " ")}
							</span>
							: <span className="text-xl">{value.toString()}</span>
						</p>
					))}
				</div>
			</div>
			<img
				className="mx-auto pt-2 max-h-[400px]"
				src={tabs[currentTab].image}
				alt={tabs[currentTab].title + "image"}
			></img>
		</div>
	);
}

export default Timeline;
