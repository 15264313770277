import AllPartners from "../../../assets/partners_logo.png";
import BeerDrawing from "../../../assets/beer_drawing.png";
import CAPPartnership from "../../../assets/CAP logo.png";
import SaskSeed from '../../../assets/saskseed.png'

function Partners() {
  return (
    <div className="px-16 pt-40 bg-background bg-background">
      <h1 className="font-brewski text-7xl tablet:text-8xl text-orange-500 text-center pb-20">
        Partners
      </h1>
      <img
        className="max-h-[172px] mx-auto pb-20 object-fill"
        src={CAPPartnership}
        alt="Canadian Agricultural Partnership logo, Government of Saskatchewan logo, Government of Canada logo"
      ></img>
      <img
        className="max-h-[750px] mx-auto object-fill"
        src={AllPartners}
        alt="Partnered Logos"
      ></img>
      <img
        className="h-[375px] mx-auto pr-[30px] pb-40"
        src={SaskSeed}
        alt="Sask Seed Logo"
      ></img>
      <img
        className="mx-auto max-h-[550px]"
        src={BeerDrawing}
        alt="Beer Illustration"
      ></img>
    </div>
  );
}

export default Partners;
