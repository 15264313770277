import { Button, Input } from "semantic-ui-react";
import { useHistory } from 'react-router-dom';
import styled from 'styled-components'

import BeerGlass from '../assets/beerGlassTransparentBG.svg'

const PopupContainer = styled.div`
    display: flex;
    position: relative;
    height: 100%;
    width: 100%;
`

const PopupBG = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 30%;
    height: 100%;
    border-radius: 20px 0 0 20px;
    background: rgba(107, 134, 92, 0.5);
`

const PopupText = styled.div`
    font-size: 20px;
    font-weight: 600;
    line-height: 140%;
    font-family: 'Poppins';
    color: #252B42;
    position: absolute;
    right: 50px;
    bottom: 112px;
    width: 50%;
`

const PopupImage = styled.img`
    z-index: 2;
    position: absolute;
    right: -150px;
    top: 50px;
`

const PopupButton = styled.button`
    background-color: #5A876F;
    border: none;
    border-radius: 0 10px 10px 0;
    color: white;
    padding: 0 20px;
`

const PopupSpan = styled.span`
    color: #5D7745;
`

export default function CodePopup({lotNumber, setLotNumber}) {
    let history = useHistory();

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            history.push(`/trace/${lotNumber}`)
        }
    }

    return (
        <PopupContainer>
            <PopupBG>
                <PopupImage src={BeerGlass} alt="Beer Glass" />
            </PopupBG>
            <PopupText>
                <p>We're hard at work developing a newer, greater system to trace your beer.</p>
                <p>Punch in lot code <PopupSpan className="popup-code">0785</PopupSpan> for an example of what info we've got for you.</p>
                <Input
                    size={'mini'}
                    className={'trace-number-input'}
                    action={
                        <PopupButton
                            onClick={(event, data) => {
                                history.push(`/trace/${lotNumber}`)
                            }}
                            onKeyPress={handleKeyPress}
                        >
                            Search
                        </PopupButton>
                    }
                    placeholder={'Trace Number'}
                    onChange={(event, data) => {
                        setLotNumber((data || {}).value);
                    }}
                    onKeyDown={handleKeyPress}
                />
            </PopupText>
        </PopupContainer>
    )
}