import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './MoreInfo.css'
import {Container, Header, Grid, Image, Icon, Button, Segment, Menu} from "semantic-ui-react";
import wheatIconGreen from '../../assets/icons/wheat_green.png';
import wheatIconWhite from '../../assets/icons/wheat_white.png';
import beerIcon from '../../assets/icons/beer_can_green.png';
import maltIcon from '../../assets/icons/malt_white.png';
import hopsIcon from '../../assets/icons/hops_green.png';
import { useHistory } from 'react-router-dom';
import placeholder from "../../assets/placeholder.jpg";
// import ReactMapGL, {Marker} from "react-map-gl";
import map_style from "../map/map-style.json";

import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps';

const MAP_KEY = 'AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo';
const googleMapURL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo&v=3.exp&libraries=geometry,drawing,places'

const SIZE = 20;

const MoreInfoMalt = props => {

    let history = useHistory();

    const traceId = window.location.pathname.split('/')[3];

    const [mount, setMount] = useState(false);
    let ga = props.ga;

    if(!mount){
        setMount(true);
    }

    useEffect(() => {
        ga.pageview(window.location.pathname)
    }, [mount]);

    // const [viewport, setViewport] = useState({
    //     width: 400,
    //     height: 400,
    //     latitude: 53.553673,
    //     longitude: -113.488609,
    //     zoom: 9
    // });

    const MaltMap = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={5}
            defaultCenter={{ lat: 51.395815, lng: -113.17907 }}
            defaultOptions={{ fullScreenControl: false, scrollWheel: false }}
        >
            <Marker position={{ lat: 52.39291, lng: -113.17907 }} />
            <Marker position={{ lat: 50.39872, lng: -119.22462 }} />
        </GoogleMap>

    ));

    return (
        <div className={'root-more-info'}>
            <Container className={'more-info-header'}>
                <Grid columns={1} className={'header-grid'} centered>
                    <Grid.Column centered verticalAlign={'middle'}>
                        <Grid.Row centered textAlign={'middle'}>
                            <Header inverted>
                                DISCOVER MORE
                            </Header>
                        </Grid.Row>

                    </Grid.Column>
                </Grid>
            </Container>
            <Container className={'more-info-navigation'}>
                <Grid columns={4} centered>
                    <Grid.Row textAlign={'middle'} centered>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                color={'green'}
                                basic
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={wheatIconGreen}/>}
                                onClick={(event => {
                                    history.push(`/moreinfo/barley/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={maltIcon}/>}
                                onClick={(event => {
                                    history.push(`/moreinfo/malt/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                color={'green'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={hopsIcon}/>}
                                basic
                                onClick={(event => {
                                    history.push(`/moreinfo/hops/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                color={'green'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={beerIcon}/>}
                                basic
                                onClick={(event => {
                                    history.push(`/moreinfo/brew/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Container>
            <Container className={'page-contents'}>
                <Grid columns={1}>
                    <Grid.Column>
                        <Grid.Row>
                            <Container textAlign={'left'}>
                                <div className={'section-heading'}>
                                    Barley Quality
                                </div>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    Barley that is malting quality must meet strict criteria to be accepted, with a premium paid to farmers. Maintaining controls on these quality factors is necessary to ensure good processing efficiency.
                                </p>
                            </Container>
                        </Grid.Row>

                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>PROTEIN: </b> Determined by both agronomic and environmental factors. Hot and dry seasons and excessive use of nitrogen fertilizer tend to result in higher protein. While cool, wet seasons decrease protein. Ideal range is between 9.5 – 12.5%. Protein affects the chemical composition and enzyme levels of the finished malt. High protein limits starch content and the amount of extract available to the brewer. If the protein is too low, there may be insufficient enzymatic activity to modify the kernel and break down starch for brewing.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>MOISTURE: </b>
                                    Is the measure of water content of grain, a low percentage is desired to maintain storage quality to reduce the risk of mould growth and to preserve the germination ability.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>TEST WEIGHT: </b>
                                    Is the measure weight of the volume of grain expressed in grams per half litre. Higher test weight is more desirable as there is more nutrient density (starch-rich endosperm) with less bran and hull. Lower test weight is more common when the crop has endured stress (environmental or disease) at some point during the grain-filling period or when frost ends the growing season prior to maturity.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>PLUMPNESS: </b>
                                    Tests the percentage of plump (wide) kernels. Plump and uniform kernels are desirable as plump kernels contain higher levels of starch, which produce more beer from a given weight of malt.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>CHITTING: </b>
                                    Records the premature sprouting of kernels, with a low number desirable to maintain a high level of germination energy during storage. Pre-germination can occur when mature barley is still in the field during wet weather before harvest. Severely pre-sprouted % alludes to a high chance germination will decline rapidly within a couple of months and is often rejected and downgraded to feed barley.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>GERMINATION: </b>
                                    Kernels are soaked in moisture over a 72-hour period, with the percentage sprouted (alive) counted. Malt quality barley should have a least 95%, as the kernel has to be alive to be processed into malt.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>PEELED AND BROKEN: </b>
                                    Barley has a thin, dry outer layer called a husk. It is important this remains in-tact as it protects the shoot growth during the malting process helping to regulate the uptake of water. Broken kernels are less than ¾ of a whole kernel and/or with the germ end broken off. Broken kernels will not germinate or may grow in an abnormal manner.
                                </p>
                            </Container>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
                <Grid columns={1}>
                    <Grid.Column>
                        <Grid.Row>
                            <Container textAlign={'left'}>
                                <div className={'section-heading'}>
                                    Malt Process
                                </div>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    A three-stage process (steeping, germination and drying), that has remained practically unchanged for thousands of years.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>STEEPING: </b>
                                    Barley kernels are soaked in water to raise moisture level from harvested levels to 45%. This is generally achieved over a 48-hour period with three wet cycles and air rest (water drained) between cycles. The first cycle washes and cleans the barley, with the absorbing oxygen from the water kickstarting kernel growth. During air rests, fans supply fresh air to remove excess carbon dioxide produced by grain respiration. Once the barley has reached the desired moisture level, it will begin to grow steadily and uniformly and is transferred to the germination box.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>GERMINATION: </b>
                                    Over four days, steady temperature and moisture (between 10 and 16 °C) ensures consistent growth rates as the kernels begin sprouting. To keep the sprouted roots from tangling and to continue providing fresh air, the bed is stirred every few hours. Once the acrospire (roots) have reached ¾ to full length of the kernel, it is ready for the kiln.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>KILN: </b>
                                    The final stage where the flavours and colours are established over a 24-hour period. Air temperature is slowly increased to evaporate the moisture stopping the germination process. As the grain dries, air temperature is increased to reach the desired colour and flavour of the malt. For the darker coloured malts, malt will be further processed in a drum roaster. The roaster reaches higher temperatures and cools the malt quickly to prevent further colour change or from catching on fire.
                                </p>
                            </Container>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>

                <Grid columns={1}>
                    <Grid.Column>
                        <Grid.Row>
                            <Container textAlign={'left'}>
                                <div className={'section-heading'}>
                                    Location
                                </div>
                            </Container>
                        </Grid.Row>
                        <Grid.Row textAlign={'center'} columns={1}>
                            <Grid.Column>
                                <Segment className={'map-segment'} vertical>
                                    {/*<ReactMapGL*/}
                                    {/*    {...viewport}*/}
                                    {/*    onViewportChange={nextViewport => setViewport(nextViewport)}*/}
                                    {/*    mapboxApiAccessToken={mapToken}*/}
                                    {/*    mapStyle={map_style}*/}
                                    {/*    width={'100%'}*/}
                                    {/*    height={'100%'}*/}
                                    {/*>*/}
                                    {/*    <Marker*/}
                                    {/*        latitude={53.553673}*/}
                                    {/*        longitude={-113.488609}*/}
                                    {/*    >*/}
                                    {/*        <svg*/}
                                    {/*            height={30}*/}
                                    {/*            viewBox="0 0 24 24"*/}
                                    {/*            style={{*/}
                                    {/*                cursor: 'pointer',*/}
                                    {/*                fill: '#4d8921',*/}
                                    {/*                stroke: 'none',*/}
                                    {/*                transform: `translate(${-SIZE / 2}px,${-SIZE}px)`*/}
                                    {/*            }}*/}
                                    {/*        >*/}
                                    {/*            <path d={ICON} />*/}
                                    {/*        </svg>*/}
                                    {/*    </Marker>*/}
                                    {/*</ReactMapGL>*/}
                                    <MaltMap
                                        googleMapURL={'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo&v=3.exp&libraries=geometry,drawing,places'}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `500px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                    />
                                </Segment>
                                <Container className={'back-to-timeline'}>
                                    <Grid>
                                        <Grid.Row centered>
                                            <Button size={'huge'} className={'ui custom button'} onClick={event => {

                                                history.push(`/trace/${traceId}`)

                                            }}>
                                                Back to Timeline
                                            </Button>
                                        </Grid.Row>
                                    </Grid>
                                </Container>
                            </Grid.Column>
                        </Grid.Row>


                    </Grid.Column>
                </Grid>

                <Grid columns={2}>

                    <Grid.Column>
                        <Menu secondary>
                            <Menu.Item
                                name={'home'}
                                onClick={event => {
                                    history.push('/')
                                }}
                            />
                            <Menu.Item
                                name={'Journey'}
                                onClick={event => {
                                    history.push(`/trace/${traceId}`)
                                }}
                            />
                            <Menu.Item
                                name={'About Us'}
                                onClick={event => {
                                    history.push(`/about/${traceId}`)
                                }}
                            />
                        </Menu>
                    </Grid.Column>
                    <Grid.Column floated={'right'}>
                        <Container textAlign={'right'}>
                            <Menu.Item
                                href={"https://www.facebook.com/blindmanbrewing/"}
                            >
                                <Icon size={'large'} color={'black'} link name={'facebook'}/>
                            </Menu.Item>
                            <Menu.Item
                                href={"https://twitter.com/blindmanbrewery"}
                            >
                                <Icon size={'large'} color={'green'} link name={'twitter'}/>
                            </Menu.Item>
                            <Menu.Item
                                href={"https://www.instagram.com/blindmanbrewery/"}
                            >
                                <Icon size={'large'} color={'black'} link name={'instagram'}/>
                            </Menu.Item>

                        </Container>

                    </Grid.Column>

                </Grid>
            </Container>
        </div>
    );
};

MoreInfoMalt.propTypes = {

};

export default MoreInfoMalt;
