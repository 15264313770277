import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './MoreInfo.css'
import {Container, Header, Grid, Image, Icon, Button, Segment, Menu} from "semantic-ui-react";
import wheatIconGreen from '../../assets/icons/wheat_green.png';
import wheatIconWhite from '../../assets/icons/wheat_white.png';
import beerIcon from '../../assets/icons/beer_can_green.png';
import maltIcon from '../../assets/icons/malt_green.png';
import hopsIcon from '../../assets/icons/hops_white.png';
import { useHistory } from 'react-router-dom';
// import ReactMapGL, {Marker} from "react-map-gl";
import map_style from "../map/map-style.json";
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps';

const MAP_KEY = 'AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo';
const googleMapURL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo&v=3.exp&libraries=geometry,drawing,places'

const SIZE = 20;

const MoreInfoHops = props => {

    const traceId = window.location.pathname.split('/')[3]

    let history = useHistory();

    const [mount, setMount] = useState(false);
    let ga = props.ga;

    if(!mount){
        setMount(true);
    }

    useEffect(() => {
        ga.pageview(window.location.pathname)
    }, [mount]);

    // const [viewport, setViewport] = useState({
    //     width: 400,
    //     height: 400,
    //     latitude: 53.553673,
    //     longitude: -113.488609,
    //     zoom: 9
    // });

    const HopsMap = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={1.5}
            defaultCenter={{ lat: 45.632694664, lng: 180.431985515 }}
            defaultOptions={{ fullScreenControl: false, scrollWheel: false }}
        >
            <Marker position={{ lat: -42.71549544636076, lng: 146.90279822159593 }} />
            <Marker position={{ lat: 46.23875413048222, lng: -120.16265829815265 }} />
            <Marker position={{ lat: 46.28846630066054, lng: -119.64786065856731 }} />
            <Marker position={{ lat: 46.63174834940715, lng: -120.61926550766822 }} />
            <Marker position={{ lat: 46.289009094695786, lng: -119.82734488987597 }} />
        </GoogleMap>


    ));

    return (
        <div className={'root-more-info'}>
            <Container className={'more-info-header'}>
                <Grid columns={1} className={'header-grid'} centered>
                    <Grid.Column centered verticalAlign={'middle'}>
                        <Grid.Row centered textAlign={'middle'}>
                            <Header inverted>
                                DISCOVER MORE
                            </Header>
                        </Grid.Row>

                    </Grid.Column>
                </Grid>
            </Container>
            <Container className={'more-info-navigation'}>
                <Grid columns={4}>
                    <Grid.Row textAlign={'middle'} centered>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                color={'green'}
                                basic
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={wheatIconGreen}/>}
                                onClick={(event => {
                                    history.push(`/moreinfo/barley/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column  textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                color={'green'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={maltIcon}/>}
                                basic
                                onClick={(event => {
                                    history.push(`/moreinfo/malt/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column  textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={hopsIcon}/>}
                                onClick={(event => {
                                    history.push(`/moreinfo/hops/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column  textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                color={'green'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={beerIcon}/>}
                                basic
                                onClick={(event => {
                                    history.push(`/moreinfo/brew/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <Container className={'page-contents'}>
                <Grid columns={1}>
                    <Grid.Column>
                        <Grid.Row>
                            <Container textAlign={'left'}>
                                <div className={'section-heading'}>
                                    Flavour Profile
                                </div>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    The hop flower gives beer its bitterness and flavouring, imparting floral, fruity, or citrus flavours and aromas. As a bonus, hops are a preservative, and extend the life of beer.
                                </p>
                            </Container>
                        </Grid.Row>

                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    There are a number of prime hops growing regions throughout the world, primarily the Yakima Valley in the US, Tasmania in Australia as well as regions in Europe and Canada. Each different hop varietal imparts different flavours and aromas and we tend to use hops that produce tropical fruit and citrus flavours.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    There are 3x main points in the brewing process where we add hops: in the boil, in the whirlpool and in the fermenter (called “dry-hopping”). Basically, bitterness from hops occurs in the boil, which we tend to shy away from. Our biggest hop additions come in the whirlpool and in the fermenter. Some of our beers, for example our Session Ale, have no bittering hops added to the boil and are “hop bursted” with only whirlpool hops added and dry-hopping.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    When you take a big breath in and get that big, fruity, hop aroma—that’s from the dry-hopping. We use “double dry-hopping” which means that hops are added both when the beer is fermented (at “high krausen”) as well as after the beer is finished fermenting.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row  columns={5} className={'section-row'} textAlign={'middle'} only={'computer'}>
                            <Container textAlign={'center'}>
                                <Menu.Item
                                    href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/vicSecret.pdf'}
                                >
                                    <Button className={'ui custom button'}>Vic Secret</Button>
                                </Menu.Item>
                                <Menu.Item
                                    href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/simcoe.pdf'}
                                >
                                    <Button className={'ui custom button'}>Simcoe</Button>
                                </Menu.Item>
                                <Menu.Item
                                    href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/idaho.pdf'}
                                >
                                    <Button className={'ui custom button'}>Idaho 7 Cryo</Button>
                                </Menu.Item>
                                <Menu.Item
                                    href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/eldorado.pdf'}
                                >
                                    <Button className={'ui custom button'}>El Dorado</Button>
                                </Menu.Item>
                                <Menu.Item
                                    href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/ekuanot.pdf'}
                                >
                                    <Button className={'ui custom button'}>Ekuanot Cryo</Button>
                                </Menu.Item>
                            </Container>

                        </Grid.Row>
                        {/*<Grid.Row  columns={5} className={'section-row'} textAlign={'middle'} only={'mobile'}>*/}
                        {/*    <Container textAlign={'center'}>*/}
                        {/*        <Menu.Item*/}
                        {/*            href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/vicSecret.pdf'}*/}
                        {/*        >*/}
                        {/*            <Button className={'ui custom button'}>Vic Secret</Button>*/}
                        {/*        </Menu.Item>*/}
                        {/*        <Menu.Item*/}
                        {/*            href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/simcoe.pdf'}*/}
                        {/*        >*/}
                        {/*            <Button className={'ui custom button'}>Simcoe</Button>*/}
                        {/*        </Menu.Item>*/}

                        {/*    </Container>*/}

                        {/*</Grid.Row>*/}
                        {/*<Grid.Row  columns={5} className={'section-row'} textAlign={'middle'} only={'computer'}>*/}
                        {/*    <Container textAlign={'center'}>*/}

                        {/*        <Menu.Item*/}
                        {/*            href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/idaho.pdf'}*/}
                        {/*        >*/}
                        {/*            <Button className={'ui custom button'}>Idaho 7 Cryo</Button>*/}
                        {/*        </Menu.Item>*/}
                        {/*        <Menu.Item*/}
                        {/*            href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/eldorado.pdf'}*/}
                        {/*        >*/}
                        {/*            <Button className={'ui custom button'}>El Dorado</Button>*/}
                        {/*        </Menu.Item>*/}

                        {/*    </Container>*/}

                        {/*</Grid.Row>*/}
                        {/*<Grid.Row  columns={5} className={'section-row'} textAlign={'middle'} only={'computer'}>*/}
                        {/*    <Container textAlign={'center'}>*/}
                        {/*        <Menu.Item*/}
                        {/*            href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/ekuanot.pdf'}*/}
                        {/*        >*/}
                        {/*            <Button className={'ui custom button'}>Ekuanot Cryo</Button>*/}
                        {/*        </Menu.Item>*/}
                        {/*    </Container>*/}

                        {/*</Grid.Row>*/}

                    </Grid.Column>
                </Grid>
                <Grid columns={1}>
                    <Grid.Column>
                        <Grid.Row>
                            <Container textAlign={'left'}>
                                <div className={'section-heading'}>
                                    Location
                                </div>
                            </Container>
                        </Grid.Row>
                        <Grid.Row textAlign={'center'} columns={1}>
                            <Grid.Column>
                                <Segment className={'map-segment'} vertical>
                                    {/*<ReactMapGL*/}
                                    {/*    {...viewport}*/}
                                    {/*    onViewportChange={nextViewport => setViewport(nextViewport)}*/}
                                    {/*    mapboxApiAccessToken={mapToken}*/}
                                    {/*    mapStyle={map_style}*/}
                                    {/*    width={'100%'}*/}
                                    {/*    height={'100%'}*/}
                                    {/*>*/}
                                    {/*    <Marker*/}
                                    {/*        latitude={53.553673}*/}
                                    {/*        longitude={-113.488609}*/}
                                    {/*    >*/}
                                    {/*        <svg*/}
                                    {/*            height={30}*/}
                                    {/*            viewBox="0 0 24 24"*/}
                                    {/*            style={{*/}
                                    {/*                cursor: 'pointer',*/}
                                    {/*                fill: '#4d8921',*/}
                                    {/*                stroke: 'none',*/}
                                    {/*                transform: `translate(${-SIZE / 2}px,${-SIZE}px)`*/}
                                    {/*            }}*/}
                                    {/*        >*/}
                                    {/*            <path d={ICON} />*/}
                                    {/*        </svg>*/}
                                    {/*    </Marker>*/}
                                    {/*</ReactMapGL>*/}
                                    <HopsMap
                                        googleMapURL={'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo&v=3.exp&libraries=geometry,drawing,places'}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `500px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                    />

                                </Segment>
                                <Container className={'back-to-timeline'}>
                                    <Grid>
                                        <Grid.Row centered>
                                            <Button size={'huge'} className={'ui custom button'} onClick={event => {

                                                history.push(`/trace/${traceId}`)

                                            }}>
                                                Back to Timeline
                                            </Button>
                                        </Grid.Row>
                                    </Grid>
                                </Container>
                            </Grid.Column>
                        </Grid.Row>


                    </Grid.Column>
                </Grid>

                <Grid columns={2}>

                    <Grid.Column>
                        <Menu secondary>
                            <Menu.Item
                                name={'home'}
                                onClick={event => {
                                    history.push('/')
                                }}
                            />
                            <Menu.Item
                                name={'Journey'}
                                onClick={event => {
                                    history.push(`/trace/${traceId}`)
                                }}
                            />
                            <Menu.Item
                                name={'About Us'}
                                onClick={event => {
                                    history.push(`/about/${traceId}`)
                                }}
                            />
                        </Menu>
                    </Grid.Column>
                    <Grid.Column floated={'right'}>
                        <Container textAlign={'right'}>
                            <Menu.Item
                                href={"https://www.facebook.com/blindmanbrewing/"}
                            >
                                <Icon size={'large'} color={'black'} link name={'facebook'}/>
                            </Menu.Item>
                            <Menu.Item
                                href={"https://twitter.com/blindmanbrewery"}
                            >
                                <Icon size={'large'} color={'green'} link name={'twitter'}/>
                            </Menu.Item>
                            <Menu.Item
                                href={"https://www.instagram.com/blindmanbrewery/"}
                            >
                                <Icon size={'large'} color={'black'} link name={'instagram'}/>
                            </Menu.Item>
                        </Container>
                    </Grid.Column>
                </Grid>
            </Container>
        </div>
    );
};

MoreInfoHops.propTypes = {

};

export default MoreInfoHops;
