import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

import {useHistory, Link} from "react-router-dom";
import {Button, Container, Grid, Header, Icon, Image, Menu, Segment} from "semantic-ui-react";
import blindmanBreweryLogo from "../../assets/blindmanLogo.png";
// import ReactMapGL, {Marker} from 'react-map-gl';
import map_style from './map-style.json';
import './Map.css'

import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps';

const MAP_KEY = 'AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo';
const googleMapURL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo&v=3.exp&libraries=geometry,drawing,places'

const SIZE = 20;

const Map = props => {

    const [mount, setMount] = useState(false);
    let ga = props.ga;

    if(!mount){
        setMount(true);
    }

    useEffect(() => {
        ga.pageview(window.location.pathname)
    }, [mount]);

    const history = useHistory()

    const traceId = window.location.pathname.split('/')[2]
    // const [viewport, setViewport] = useState({
    //     width: 400,
    //     height: 400,
    //     latitude: 53.553673,
    //     longitude: -113.488609,
    //     zoom: 9
    // });

    const FullMap = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={1.5}
            defaultCenter={{ lat: 45.632694664, lng: 190.431985515 }}
            defaultOptions={{ fullScreenControl: false, scrollWheel: false }}
        >
            <Marker position={{ lat: -42.71549544636076, lng: 146.90279822159593 }} />
            <Marker position={{ lat: 46.23875413048222, lng: -120.16265829815265 }} />
            <Marker position={{ lat: 46.28846630066054, lng: -119.64786065856731 }} />
            <Marker position={{ lat: 46.63174834940715, lng: -120.61926550766822 }} />
            <Marker position={{ lat: 46.289009094695786, lng: -119.82734488987597 }} />
            <Marker position={{ lat: 52.678671, lng: -112.150690 }} />
            <Marker position={{ lat: 51.88403, lng: -109.62689 }} />
            <Marker position={{ lat: 51.74709, lng: -114.04311 }} />
            <Marker position={{ lat: 51.97354, lng: -113.47006 }} />
            <Marker position={{ lat: 52.9378, lng: -112.1992 }} />
            <Marker position={{ lat: 50.68029, lng: -113.61712 }} />
            <Marker position={{ lat: 52.46699, lng: -113.70463 }} />
            <Marker position={{ lat: 52.39291, lng: -113.17907 }} />
            <Marker position={{ lat: 50.39872, lng: -119.22462 }} />
        </GoogleMap>

    ));


            return (
                <div className={'root-map'}>
                    <Container className={'the-map-header'}>
                        <Grid columns={3} className={'header-grid'} centered>
                            <Grid.Column verticalAlign={'top'}>
                                <Grid.Row textAlign={'left'}>
                                    <Image src={blindmanBreweryLogo} className={'lot-trace-blindman-logo'} floated={'left'} size={'small'}/>
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column verticalAlign={'middle'}>
                                <Grid.Row centered textAlign={'center'}>
                                    <Header inverted>
                                        Map
                                    </Header>
                                </Grid.Row>
                            </Grid.Column>
                            <Grid.Column verticalAlign={'top'}>
                                <Grid.Row centered textAlign={'right'}>
                                    <Menu secondary inverted floated={'right'}>
                                        <Menu.Item
                                            name={'Home'}
                                            onClick={event => {
                                                history.push('/')
                                            }}
                                        />
                                        <Menu.Item
                                            name={'Journey'}
                                            onClick={event => {
                                                history.push(`/trace/${traceId}`)
                                            }}
                                        />
                                        <Menu.Item
                                            name={'About Us'}
                                            onClick={event => {
                                                history.push(`/about/${traceId}`)
                                            }}
                                        />
                                    </Menu>
                                </Grid.Row>
                            </Grid.Column>
                        </Grid>
                    </Container>
                    <Container className={'page-contents-map'}>
                        <Grid columns={1}>
                            <Grid.Row>
                                <Grid.Column>
                                    <Container className={'map-container'}>

                                            {/*<ReactMapGL*/}
                                            {/*    {...viewport}*/}
                                            {/*    onViewportChange={nextViewport => setViewport(nextViewport)}*/}
                                            {/*    mapboxApiAccessToken={mapToken}*/}
                                            {/*    mapStyle={map_style}*/}
                                            {/*    width={'100%'}*/}
                                            {/*    height={'100%'}*/}
                                            {/*>*/}
                                            {/*    <Marker*/}
                                            {/*        latitude={53.553673}*/}
                                            {/*        longitude={-113.488609}*/}
                                            {/*    >*/}
                                            {/*        <svg*/}
                                            {/*            height={30}*/}
                                            {/*            viewBox="0 0 24 24"*/}
                                            {/*            style={{*/}
                                            {/*                cursor: 'pointer',*/}
                                            {/*                fill: '#4d8921',*/}
                                            {/*                stroke: 'none',*/}
                                            {/*                transform: `translate(${-SIZE / 2}px,${-SIZE}px)`*/}
                                            {/*            }}*/}
                                            {/*        >*/}
                                            {/*            <path d={ICON} />*/}
                                            {/*        </svg>*/}
                                            {/*    </Marker>*/}
                                            {/*</ReactMapGL>*/}
                                            <FullMap
                                                googleMapURL={'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo&v=3.exp&libraries=geometry,drawing,places'}
                                                loadingElement={<div style={{ height: `100%` }} />}
                                                containerElement={<div style={{ height: `600px` }} />}
                                                mapElement={<div style={{ height: `100%` }} />}
                                            />
                                    </Container>
                                </Grid.Column>
                            </Grid.Row>
                        </Grid>

                        <Grid columns={2}>

                            <Grid.Column>
                                <Menu secondary>
                                    <Menu.Item
                                        name={'home'}
                                        onClick={event => {
                                            history.push('/')
                                        }}
                                    />
                                    <Menu.Item
                                        name={'Journey'}
                                        onClick={event => {
                                            history.push(`/trace/${traceId}`)
                                        }}
                                    />

                                    <Menu.Item
                                        name={'About Us'}
                                        onClick={event => {
                                            history.push(`/about/${traceId}`)
                                        }}
                                    />
                                </Menu>
                            </Grid.Column>
                            <Grid.Column floated={'right'}>
                                <Container textAlign={'right'}>
                                    <Menu.Item
                                        href={"https://www.facebook.com/blindmanbrewing/"}
                                    >
                                        <Icon size={'large'} color={'black'} link name={'facebook'}/>
                                    </Menu.Item>
                                    <Menu.Item
                                        href={"https://twitter.com/blindmanbrewery"}
                                    >
                                        <Icon size={'large'} color={'green'} link name={'twitter'}/>
                                    </Menu.Item>
                                    <Menu.Item
                                        href={"https://www.instagram.com/blindmanbrewery/"}
                                    >
                                        <Icon size={'large'} color={'black'} link name={'instagram'}/>
                                    </Menu.Item>

                                </Container>

                            </Grid.Column>

                        </Grid>

                    </Container>
                </div>
            );
};

Map.propTypes = {
    
};

export default Map;
