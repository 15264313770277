import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './MoreInfo.css'
import {Container, Header, Grid, Image, Icon, Button, Segment, Menu} from "semantic-ui-react";
import wheatIconGreen from '../../assets/icons/wheat_green.png';
import wheatIconWhite from '../../assets/icons/wheat_white.png';
import beerIcon from '../../assets/icons/beer_can_green.png';
import maltIcon from '../../assets/icons/malt_green.png';
import hopsIcon from '../../assets/icons/hops_green.png';
import { useHistory } from 'react-router-dom';
//import { Timeline, TimelineItem }  from 'vertical-timeline-component-for-react';
import placeholder from '../../assets/placeholder.jpg';
import precipitation from '../../assets/precipitation.png';
import meanTemp from '../../assets/meanTemp.png';
import Map from "../map/Map";
import map_style from "../map/map-style.json";
// import ReactMapGL, {Marker} from "react-map-gl";
import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps';

const MAP_KEY = 'AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo';
const googleMapURL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo&v=3.exp&libraries=geometry,drawing,places'

const SIZE = 20;

const MoreInfoBarley = props => {

    const traceId = window.location.pathname.split('/')[3]

    const [mount, setMount] = useState(false);
    let ga = props.ga;

    if(!mount){
        setMount(true);
    }

    useEffect(() => {
        ga.pageview(window.location.pathname)
    }, [mount]);

    const FarmerMap = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={6}
            defaultCenter={{ lat: 51.983570167, lng: -112.517845 }}
            defaultOptions={{ fullScreenControl: false, scrollWheel: false }}
        >
            <Marker position={{ lat: 52.678671, lng: -112.150690 }} />
            <Marker position={{ lat: 51.88403, lng: -109.62689 }} />
            <Marker position={{ lat: 51.74709, lng: -114.04311 }} />
            <Marker position={{ lat: 51.97354, lng: -113.47006 }} />
            <Marker position={{ lat: 52.9378, lng: -112.1992 }} />
            <Marker position={{ lat: 50.68029, lng: -113.61712 }} />
        </GoogleMap>

    ));

    let history = useHistory();

    return (
        <div className={'root-more-info'}>
            <Container className={'more-info-header'}>
                <Grid columns={1} className={'header-grid'} centered>
                    <Grid.Column centered verticalAlign={'middle'}>
                        <Grid.Row centered textAlign={'middle'}>
                            <Header inverted>
                                DISCOVER MORE
                            </Header>
                        </Grid.Row>

                    </Grid.Column>
                </Grid>
            </Container>
            <Container className={'more-info-navigation'} textAlign={'center'}>
                <Grid columns={4} centered>
                    <Grid.Row textAlign={'middle'} centered>
                        <Grid.Column centerd textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={wheatIconWhite}/>}
                                onClick={(event => {
                                    history.push(`/moreinfo/barley/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                color={'green'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={maltIcon}/>}
                                basic
                                onClick={(event => {
                                    history.push(`/moreinfo/malt/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                color={'green'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={hopsIcon}/>}
                                basic
                                onClick={(event => {
                                    history.push(`/moreinfo/hops/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                color={'green'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={beerIcon}/>}
                                basic
                                onClick={(event => {
                                    history.push(`/moreinfo/brew/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                    </Grid.Row>

                </Grid>
            </Container>
            <Container className={'page-contents'}>
                <Grid columns={1}>
                    <Grid.Column>
                        <Grid.Row>
                            <Container textAlign={'left'}>
                                <div className={'section-heading'}>
                                    Certified Seed
                                </div>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    Certified seed is the product of a production process designed to deliver specific plant breeding achievements to farmers and the food industry. In other words, it is true-to-type. True-to type means all the benefits developed by the plant breeder are retained as the seed is multiplied over a number of specific number of generations (to the Certified seed stage) from the small amount of seed developed by the plant breeder.
                                </p>
                            </Container>
                        </Grid.Row>

                        <Grid.Row className={'section-row'}>
                            <Grid columns={2} className={'button-container'}>
                                <Grid.Row textAlign={'middle'} centered>
                                    <Grid.Column textAlign={'middle'}>
                                        <Menu.Item
                                            href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/copeland.pdf'}
                                        >
                                            <Button className={'ui custom button'} >
                                                Copeland
                                            </Button>
                                        </Menu.Item>
                                    </Grid.Column>
                                    <Grid.Column textAlign={'middle'}>
                                        <Menu.Item
                                            href={'https://storage.googleapis.com/gd-public-assets/brewery-discovery/synergy.pdf'}
                                        >
                                            <Button className={'ui custom button'} >
                                                Synergy
                                            </Button>
                                        </Menu.Item>
                                    </Grid.Column>
                                </Grid.Row>

                            </Grid>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
                <Grid columns={1}>
                    <Grid.Column>
                        <Grid.Row>
                            <Container textAlign={'left'}>
                                <div className={'section-heading'}>
                                    Growing Season
                                </div>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    The 2020 Central Alberta growing season could be summarised as “ideal timing” from planting right through to harvest! Spurred on by a relatively dry and warm spring, barley planting got off to a promising start. The second half of May brought the first major rainfall event of 2020, kickstarting excellent germination and plant establishment. From May to August, 337mm of rain was recorded, +15% above the long-term average. The weather turned dry from the second half of August, which meant a disease free and high-quality crop that was quickly harvested in September without any major delays.
                                </p>

                                <p className={'section-text'}>
                                    Across Canada 2020 was the biggest harvested barley area in over a decade at 2.8 million hectares. Average yield was 3.8 tonnes/ha (just above the five-year average) which produced a bumper crop of 10.7 million tonnes (+23% above five-year average). Of this volume, just over 3 million tonnes was classified as “malt” for the brewery industry, with Copeland and Synergy representing 70% of all malting barley varieties.
                                </p>
                            </Container>
                        </Grid.Row>

                        <Grid.Row>
                            <Grid columns={2}>
                                <Grid.Column>
                                    <Image src={precipitation}/>
                                </Grid.Column>
                                <Grid.Column>
                                    <Image src={meanTemp}/>
                                </Grid.Column>
                            </Grid>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
                <Grid columns={1}>
                    <Grid.Column>
                        <Grid.Row>
                            <Container textAlign={'left'}>
                                <div className={'section-heading'}>
                                    Location
                                </div>
                            </Container>
                        </Grid.Row>
                        <Grid.Row textAlign={'center'} columns={1}>
                            <Grid.Column>
                                <Segment className={'map-segment'} vertical>
                                    {/*<ReactMapGL*/}
                                    {/*    {...viewport}*/}
                                    {/*    onViewportChange={nextViewport => setViewport(nextViewport)}*/}
                                    {/*    mapboxApiAccessToken={mapToken}*/}
                                    {/*    mapStyle={map_style}*/}
                                    {/*    width={'100%'}*/}
                                    {/*    height={'100%'}*/}
                                    {/*>*/}
                                    {/*    <Marker*/}
                                    {/*        latitude={53.553673}*/}
                                    {/*        longitude={-113.488609}*/}
                                    {/*    >*/}
                                    {/*        <svg*/}
                                    {/*            height={30}*/}
                                    {/*            viewBox="0 0 24 24"*/}
                                    {/*            style={{*/}
                                    {/*                cursor: 'pointer',*/}
                                    {/*                fill: '#4d8921',*/}
                                    {/*                stroke: 'none',*/}
                                    {/*                transform: `translate(${-SIZE / 2}px,${-SIZE}px)`*/}
                                    {/*            }}*/}
                                    {/*        >*/}
                                    {/*            <path d={ICON} />*/}
                                    {/*        </svg>*/}
                                    {/*    </Marker>*/}
                                    {/*</ReactMapGL>*/}
                                    <FarmerMap
                                        googleMapURL={'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo&v=3.exp&libraries=geometry,drawing,places'}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `500px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                    />
                                </Segment>
                                <Container className={'back-to-timeline'}>
                                    <Grid>
                                        <Grid.Row centered>
                                            <Button size={'huge'} className={'ui custom button'} onClick={event => {

                                                    history.push(`/trace/${traceId}`)

                                            }}>
                                                Back to Timeline
                                            </Button>
                                        </Grid.Row>
                                    </Grid>
                                </Container>
                            </Grid.Column>
                        </Grid.Row>


                    </Grid.Column>
                </Grid>

                <Grid columns={2}>

                        <Grid.Column>
                            <Menu secondary>
                                <Menu.Item
                                    name={'home'}
                                    onClick={event => {
                                        history.push('/')
                                    }}
                                />
                                <Menu.Item
                                    name={'Journey'}
                                    onClick={event => {
                                        history.push(`/trace/${traceId}`)
                                    }}
                                />
                                <Menu.Item
                                    name={'About Us'}
                                    onClick={event => {
                                        history.push(`/about/${traceId}`)
                                    }}
                                />
                            </Menu>
                        </Grid.Column>
                        <Grid.Column floated={'right'}>
                            <Container textAlign={'right'}>
                                <Menu.Item
                                    href={"https://www.facebook.com/blindmanbrewing/"}
                                >
                                    <Icon size={'large'} color={'black'} link name={'facebook'}/>
                                </Menu.Item>
                                <Menu.Item
                                    href={"https://twitter.com/blindmanbrewery"}
                                >
                                    <Icon size={'large'} color={'green'} link name={'twitter'}/>
                                </Menu.Item>
                                <Menu.Item
                                    href={"https://www.instagram.com/blindmanbrewery/"}
                                >
                                    <Icon size={'large'} color={'black'} link name={'instagram'}/>
                                </Menu.Item>

                            </Container>

                        </Grid.Column>

                </Grid>







            </Container>
        </div>
    );
};

MoreInfoBarley.propTypes = {
    
};

export default MoreInfoBarley;
