import React from "react";
import ConnectByGd from "../../../assets/connect_by_gd.png";
import SubscribeBar from "../../../components/SubscribeBar";

function Footer() {
	return (
		<div className="px-16 pt-16 pb-8 bg-footer">
			<h1 className="text-5xl text-black text-center font-brewski pb-4">
				What's in Your Beer?
			</h1>
			<div className="max-w-2xl text-justify mx-auto pb-8">
				<p className="text-2xl font-brewski text-orange-900">
					Grain Discovery wants to show you, with our new traceability platform
					that will connect Maltsters, Brewers and beer drinkers! If you're
					looking for greater transparency in your food, leave your contact info
					below or contact us at{" "}
					<span className="text-blue-800">(343) 600-7628!</span>
				</p>
			</div>
			<SubscribeBar />
			<div className="space-x-3 z-10 mx-auto w-max pb-8">
				{mediaItem.map((media) => (
					<SocialButtons
						key={media.key}
						name={media.key}
						link={media.link}
						icon={media.icon}
					/>
				))}
			</div>
			<button
				className="flex flex-row w-full justify-center"
				onClick={(e) => {
					e.preventDefault();
					window.open("https://graindiscovery.com/home");
				}}
			>
				<img
					className="h-16"
					src={ConnectByGd}
					alt="Connected by Grain Discovery"
				></img>
			</button>
		</div>
	);
}

function SocialButtons(props) {
	return (
		<button
			type="button"
			onClick={(e) => {
				e.preventDefault();
				window.open(props.link);
			}}
		>
			<div className="flex p-2 h-12 w-12 bg-white bg-opacity-30 hover:bg-opacity-60 duration-300 rounded-full items-center justify-center">
				<img src={props.icon} alt={props.name}></img>
			</div>
		</button>
	);
}
const mediaItem = [
	{
		key: "Twitter",
		icon: "/icon/twitter_icon.png",
		link: "https://twitter.com/graindiscovery",
	},
	{
		key: "Facebook",
		icon: "/icon/facebook_icon.png",
		link: "https://www.facebook.com/GrainDiscovery/",
	},
	{
		key: "Instagram",
		icon: "/icon/instagram_icon.png",
		link: "https://www.instagram.com/grain_discovery/",
	},
];
export default Footer;
