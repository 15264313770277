import React from "react";
import BlindmanLogo from "../../../assets/blindmanLogo.png";
import OldCollegeBreweryLogo from "../../../assets/old_college_brewery.png";
import BlindmanTextField from "../../../assets/blindman_text_button.png";
import HopDrawing from "../../../assets/hop_illustration/Hop.png";

function Projects() {
  return (
    <div className="px-20 py-16 bg-background bg-background relative">
      <img
        className="hidden md:block -bottom-64 right-0 absolute"
        src={HopDrawing}
        alt="Hop Illustration"
      ></img>
      <h1 className="font-brewski text-7xl tablet:text-8xl text-orange-500 text-center pb-20">
        Past Projects
      </h1>
      <div className="flex flex-col md:flex-row md:space-x-24 md:space-y-0 space-y-10 items-center justify-center">
        <img
          className="h-48 w-48"
          src={BlindmanLogo}
          alt="Blindman Beer Logo"
        ></img>
        <div className="flex flex-col">
          <p className="text-xl max-w-lg text-justify">
            <span className="font-brewski text-3xl text-orange-500">
              2021:{" "}
            </span>
            Grain Discovery and Blindman Brewing give beer lovers a new reason
            to celebrate this May 24 by launching a traceability beer, the first
            step to a fully commerical system.
          </p>
          <br />
          <a href="/trace/0785">
            <img
              className="max-w-40 tablet:max-w-[465px] max-h-40 transform -translate-x-2"
              src={BlindmanTextField}
              alt="Link to 0785 Blindman Traceability"
            ></img>
          </a>
        </div>
      </div>

      <div className="pt-20 flex flex-col md:flex-row md:space-x-24 md:space-y-0 space-y-10 items-center justify-center">
        <img
          className="h-48 w-48"
          src={OldCollegeBreweryLogo}
          alt="Old College Brewery Logo"
        ></img>
        <div className="flex flex-col">
          <p className="text-xl max-w-lg text-justify pb-4">
            <span className="font-brewski text-3xl text-orange-500">
              2020:{" "}
            </span>
            Grain Discovery and Olds College launched a beer thats full
            traceable from Field to Glass.
          </p>
          <br />
          <button
            type="button"
            onClick={(e) => {
              e.preventDefault();
              window.open("https://fieldtoglass.ca/");
            }}
            className="font-brewski bg-primary rounded-lg px-6 py-3 w-max text-xl text-black"
          >
            Learn More
          </button>
        </div>
      </div>
    </div>
  );
}

export default Projects;
