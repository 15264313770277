import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import './MoreInfo.css'
import {Container, Header, Grid, Image, Icon, Button, Segment, Menu} from "semantic-ui-react";
import wheatIconGreen from '../../assets/icons/wheat_green.png';
import wheatIconWhite from '../../assets/icons/wheat_white.png';
import beerIcon from '../../assets/icons/beer_can_white.png';
import maltIcon from '../../assets/icons/malt_green.png';
import hopsIcon from '../../assets/icons/hops_green.png';
import { useHistory } from 'react-router-dom';
// import ReactMapGL, {Marker} from "react-map-gl";
import map_style from "../map/map-style.json";

import { withGoogleMap, GoogleMap, Marker, withScriptjs } from 'react-google-maps';

const MAP_KEY = 'AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo';
const googleMapURL = 'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo&v=3.exp&libraries=geometry,drawing,places'

const SIZE = 20;

const MoreInfoBarley = props => {

    let history = useHistory();

    const traceId = window.location.pathname.split('/')[3]

    const [mount, setMount] = useState(false);
    let ga = props.ga;

    if(!mount){
        setMount(true);
    }

    useEffect(() => {
        ga.pageview(window.location.pathname)
    }, [mount]);

    // const [viewport, setViewport] = useState({
    //     width: 400,
    //     height: 400,
    //     latitude: 53.553673,
    //     longitude: -113.488609,
    //     zoom: 9
    // });

    const BrewMap = withScriptjs(withGoogleMap((props) =>
        <GoogleMap
            defaultZoom={10}
            defaultCenter={{ lat: 52.46699, lng: -113.70463 }}
            defaultOptions={{ fullScreenControl: false, scrollWheel: false }}
        >
            <Marker position={{ lat: 52.46699, lng: -113.70463 }} />
        </GoogleMap>

    ));

    return (
        <div className={'root-more-info'}>
            <Container className={'more-info-header'}>
                <Grid columns={1} className={'header-grid'} centered>
                    <Grid.Column centered verticalAlign={'middle'}>
                        <Grid.Row centered textAlign={'middle'}>
                            <Header inverted>
                                DISCOVER MORE
                            </Header>
                        </Grid.Row>

                    </Grid.Column>
                </Grid>
            </Container>
            <Container className={'more-info-navigation'}>
                <Grid columns={4}>
                    <Grid.Row textAlign={'middle'} centered>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                circular size={'mini'}
                                color={'green'}
                                basic
                                icon={<Image className= 'navigation-button-image'  src={wheatIconGreen}/>}
                                onClick={(event => {
                                    history.push(`/moreinfo/barley/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                color={'green'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={maltIcon}/>}
                                basic
                                onClick={(event => {
                                    history.push(`/moreinfo/malt/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                color={'green'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={hopsIcon}/>}
                                basic
                                onClick={(event => {
                                    history.push(`/moreinfo/hops/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                        <Grid.Column textAlign={'middle'}>
                            <Button
                                className={'ui custom button'}
                                circular size={'mini'}
                                icon={<Image className= 'navigation-button-image'  src={beerIcon}/>}
                                onClick={(event => {
                                    history.push(`/moreinfo/brew/${traceId}`)
                                })}
                            />
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
            </Container>
            <Container className={'page-contents'}>
                <Grid columns={1}>
                    <Grid.Column>
                        <Grid.Row>
                            <Container textAlign={'left'}>
                                <div className={'section-heading'}>
                                    Brewing
                                </div>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    Malt barley is the key ingredient in beer and provides both starch and enzymes necessary to produce the fermentable sugars which yeast then turn into alcohol. It also provides the colour and flavour which adds to the profile of each beer.
                                </p>
                            </Container>
                        </Grid.Row>

                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>BREW: </b> The malt barley is crushed in a malt cracker and mixed into a “mash tun” along with hot water. There, it is stirred and circulated to allow for enzymatic action to extract the simple sugars that the yeast will need. The hot sugar water called “wort” is then filtered out of the mash and sent to the boil kettle. The leftover mash, called “spent grains” are then sent to local farms as feed for livestock.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    In the kettle, the wort is boiler for an hour, hour and a half. This helps concentrate the wort and sterilize it. Hops are added for bitterness if desired. The wort is then spun around in a “whirlpool” to collect hops and malt particles in a “trub cone” and more hops are added for flavour and aroma.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>CHILLING AND TRANSFER: </b>
                                    The hopped wort is pumped through a heat exchanger into a fermentation tank, where it is cooled (the heat that is removed is used to pre-heat the next batch). Then, oxygen is dissolved into the cooled wort to help with yeast health.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>PITCHING YEAST: </b>
                                    The brewer adds a calculated amount of a particular brewing yeast strain. Each yeast strain is unique and can provide up to 600 different flavour components to a beer.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>FERMENTATION: </b>
                                    Initially, yeast use the oxygen present to increase its numbers. Once the oxygen is used up (1-2 days), the yeast begin to ferment (converting sugars into carbon dioxide and alcohol). Fermentation generally lasts for 7-10 days for ales and 14-21 days for lagers, after which point the temperature of the fermentation vessel is lowered and the beer is referred to as "green" beer. Hops are added for flavour and aroma. The carbon dioxide that is produced will soon be captured by us in a first-in-Canada use of a new technology. The captured CO2 will then be used to carbonate the beer later.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>CONDITIONING: </b>
                                    After fermentation, the yeast is removed, and the “green” beer is matured to allow for a full development of flavours and a smooth finish. In the case of a pilsner or other lager, this conditioning time can take a number of months. After reaching its full potential, the beer is moved to the brite tank and carbonated by bubbling CO2 through the beer at near freezing temperatures to give the beer its “fizz”.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>FURTHER CONDITIONING: </b>
                                    Some beers are transferred into other vessels like foeders (giant oak barrels) or oak barrels. Sometimes “adjuncts” or fruit is added to add another dimension of flavour. This is where the art and science of brewing meet and we wait until the beer tells us it is “ready” before we package it.
                                </p>
                            </Container>
                        </Grid.Row>
                        <Grid.Row className={'section-row'}>
                            <Container textAlign={'left'}>
                                <p className={'section-text'}>
                                    <b>PACKAGING: </b>
                                    Most of our beer is packaged into aluminium cans. This occurs on a fast moving and demanding canning line where the cans are rinsed and purged to remove any oxygen and then filled. The “end’ of the aluminium can is then placed on top and double seamed to lock it on. PakTech can carriers (return them to the brewery to be re-used and recycled!) are placed onto 4-packs of beer and then they are off-packed into cardboard flats and palletized. Other beers are bottled into 500mL bottles. Sometimes the beer is carbonated before bottling and in some special cases, sugar is added to uncarbonated beer and the beer is allowed to carbonate by itself in the bottle in a process called “bottle conditioning”.
                                </p>
                            </Container>
                        </Grid.Row>
                    </Grid.Column>
                </Grid>
                <Grid columns={1}>
                    <Grid.Column>
                        <Grid.Row>
                            <Container textAlign={'left'}>
                                <div className={'section-heading'}>
                                    Location
                                </div>
                            </Container>
                        </Grid.Row>
                        <Grid.Row textAlign={'center'} columns={1}>
                            <Grid.Column>
                                <Segment className={'map-segment'} vertical>
                                    {/*<ReactMapGL*/}
                                    {/*    {...viewport}*/}
                                    {/*    onViewportChange={nextViewport => setViewport(nextViewport)}*/}
                                    {/*    mapboxApiAccessToken={mapToken}*/}
                                    {/*    mapStyle={map_style}*/}
                                    {/*    width={'100%'}*/}
                                    {/*    height={'100%'}*/}
                                    {/*>*/}
                                    {/*    <Marker*/}
                                    {/*        latitude={53.553673}*/}
                                    {/*        longitude={-113.488609}*/}
                                    {/*    >*/}
                                    {/*        <svg*/}
                                    {/*            height={30}*/}
                                    {/*            viewBox="0 0 24 24"*/}
                                    {/*            style={{*/}
                                    {/*                cursor: 'pointer',*/}
                                    {/*                fill: '#4d8921',*/}
                                    {/*                stroke: 'none',*/}
                                    {/*                transform: `translate(${-SIZE / 2}px,${-SIZE}px)`*/}
                                    {/*            }}*/}
                                    {/*        >*/}
                                    {/*            <path d={ICON} />*/}
                                    {/*        </svg>*/}
                                    {/*    </Marker>*/}
                                    {/*</ReactMapGL>*/}
                                    <BrewMap
                                        googleMapURL={'https://maps.googleapis.com/maps/api/js?key=AIzaSyCbSWSH9yOTGYIj3EZ8ksz61ipKmhKSaPo&v=3.exp&libraries=geometry,drawing,places'}
                                        loadingElement={<div style={{ height: `100%` }} />}
                                        containerElement={<div style={{ height: `500px` }} />}
                                        mapElement={<div style={{ height: `100%` }} />}
                                    />
                                </Segment>
                                <Container className={'back-to-timeline'}>
                                    <Grid>
                                        <Grid.Row centered>
                                            <Button size={'huge'} className={'ui custom button'} onClick={event => {

                                                history.push(`/trace/${traceId}`)

                                            }}>
                                                Back to Timeline
                                            </Button>
                                        </Grid.Row>
                                    </Grid>
                                </Container>
                            </Grid.Column>
                        </Grid.Row>


                    </Grid.Column>
                </Grid>

                <Grid columns={2}>

                    <Grid.Column>
                        <Menu secondary>
                            <Menu.Item
                                name={'home'}
                                onClick={event => {
                                    history.push('/')
                                }}
                            />
                            <Menu.Item
                                name={'Journey'}
                                onClick={event => {
                                    history.push(`/trace/${traceId}`)
                                }}
                            />
                            <Menu.Item
                                name={'About Us'}
                                onClick={event => {
                                    history.push(`/about/${traceId}`)
                                }}
                            />
                        </Menu>
                    </Grid.Column>
                    <Grid.Column floated={'right'}>
                        <Container textAlign={'right'}>
                            <Menu.Item
                                href={"https://www.facebook.com/blindmanbrewing/"}
                            >
                                <Icon size={'large'} color={'black'} link name={'facebook'}/>
                            </Menu.Item>
                            <Menu.Item
                                href={"https://twitter.com/blindmanbrewery"}
                            >
                                <Icon size={'large'} color={'green'} link name={'twitter'}/>
                            </Menu.Item>
                            <Menu.Item
                                href={"https://www.instagram.com/blindmanbrewery/"}
                            >
                                <Icon size={'large'} color={'black'} link name={'instagram'}/>
                            </Menu.Item>

                        </Container>

                    </Grid.Column>

                </Grid>
            </Container>
        </div>
    );
};

MoreInfoBarley.propTypes = {

};

export default MoreInfoBarley;
