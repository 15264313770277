import React from "react";
import BeerQr from "../../../assets/beer_qr.png";
import Barley1 from "../../../assets/barley_illustration/barley_1.png";
import Barley2 from "../../../assets/barley_illustration/barley_2.png";
import Barley3 from "../../../assets/barley_illustration/barley_3.png";
import Barley4 from "../../../assets/barley_illustration/barley_4.png";

function ValueProp() {
	return (
		<div className="relative px-16 pt-20 pb-20 bg-background">
			<img
				className="hidden md:block top-[750px] right-0 absolute z-10"
				src={Barley1}
				alt="Background Barley Illustration"
			></img>
			<img
				className="hidden md:block top-[500px] left-0 absolute z-10 h-96"
				src={Barley2}
				alt="Background Barley Illustration"
			></img>
			<img
				className="hidden md:block top-0 right-0 absolute"
				src={Barley3}
				alt="Background Barley Illustration"
			></img>
			<img
				className="hidden md:block top-0 left-10 absolute"
				src={Barley4}
				alt="Background Barley Illustration"
			></img>
			<h1 className="text-orange-500 text-center text-9xl font-brewski">
				Plant <span className="text-7xl">TO</span> Pint
			</h1>
			<div className="flex flex-col-reverse md:flex-row items-center justify-center md:pl-40">
				<div className="flex flex-col md:pr-10">
					<h3 className="font-brewski text-4xl">What's in Your Beer?</h3>
					<p className="text-center text-xl max-w-md text-justify pt-4">
						We have your answers! Collaborating with a wide range of industry
						stakeholders: Barley Farmers, Malt Houses and Breweries, this secure
						system will connect previously isolated data and create a new and
						valuable digital feedback loop between partners on the brewery
						supply chains.
					</p>
					<br />
					<br />
					<p className="text-center text-xl max-w-md text-justify">
						This project is at the forefront of new and creative endeavours in
						storytelling - creating brand name recognition, add value and allow
						breweries to proactively address consumer questions on the origin
						and sustainability of their ingredients.
					</p>
				</div>

				<img
					className="max-h-[600px] md:max-w-[625px] md:w-1/2 transform translate-x-10 md:translate-x-0 md:translate-y-12"
					src={BeerQr}
					alt="main beer"
				></img>
			</div>
		</div>
	);
}

export default ValueProp;
