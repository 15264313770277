import "./App.css";
import LandingPage from "./views/landingPage/LandingPage";
import LotTrace from "./views/lotTrace/LotTrace";
import About from "./views/about/About";
import Map from "./views/map/Map";
import MoreInfoBarley from "./views/moreInfo/MoreInfoBarley";
import MoreInfoMalt from "./views/moreInfo/MoreInfoMalt";
import MoreInfoHops from "./views/moreInfo/moreInfoHops";

import {
	BrowserRouter,
	Switch,
	Route,
	useHistory,
	useLocation,
} from "react-router-dom";

import MoreInfoBrew from "./views/moreInfo/MoreInfoBrew";
import { hotjar } from "react-hotjar";
import ReactGA from "react-ga";

import ValueProp from "./views/v2/value_prop/ValueProp";
import Timeline from "./views/v2/timeline/Timeline";
import Partners from "./views/v2/partners/Parters";
import Projects from "./views/v2/projects/Projects";
import Footer from "./views/v2/footer/Footer";
import SubscribeBar from "./components/SubscribeBar";

function App(props) {
	let history = useHistory();
	hotjar.initialize(2397551);

	ReactGA.initialize("UA-181372061-5", {
		debug: true,
	});

	return (
		<>
			<BrowserRouter basename={"/"}>
				<Switch>
					<Route exact test={"test"} path={"/"}>
						<>
							<ValueProp></ValueProp>
							<Timeline></Timeline>
							<Projects></Projects>
							<Partners></Partners>
							<Footer></Footer>
						</>
					</Route>
					<Route exact path={"/trace/:id"}>
						<LotTrace ga={ReactGA} />
					</Route>
					<Route exact path={"/about/:id"}>
						<About ga={ReactGA} />
					</Route>
					<Route exact path={"/map/:id"}>
						<Map ga={ReactGA} />
					</Route>
					<Route exact path={"/moreinfo/barley/:id"}>
						<MoreInfoBarley ga={ReactGA} />
					</Route>
					<Route exact path={"/moreinfo/malt/:id"}>
						<MoreInfoMalt ga={ReactGA} />
					</Route>
					<Route exact path={"/moreinfo/hops/:id"}>
						<MoreInfoHops ga={ReactGA} />
					</Route>
					<Route exact path={"/moreinfo/brew/:id"}>
						<MoreInfoBrew ga={ReactGA} />
					</Route>
				</Switch>
			</BrowserRouter>
		</>
	);
}

export default App;
